import React from 'react'

/**
 * @param callback {function} called with params (scrollY)
 * See https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event
 */
export function useScrollAndResizeListener(callback, deps) {
	const lastKnownScrollPosition = React.useRef();
	const ticking = React.useRef();

	const onChange = React.useCallback(e => {
		lastKnownScrollPosition.current = window.scrollY;
		if (!ticking.current) {
			window.requestAnimationFrame(() => {
				callback(lastKnownScrollPosition.current);
				ticking.current = false;
			});
			ticking.current = true;
		}
	}, deps);

	React.useEffect(() => {
		lastKnownScrollPosition.current = 0;
		ticking.current = false;

		onChange(undefined);

		window.addEventListener('scroll', onChange);
		window.addEventListener('resize', onChange);
		return () => {
			window.removeEventListener('scroll', onChange);
			window.removeEventListener('resize', onChange);
		};
	}, deps);
}
