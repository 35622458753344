import React from 'react'
import { useSiteMetadata } from '../components/SiteData'
import AppLayout from '../components/AppLayout'
import PageInfo from '../components/PageInfo'
import {
	ArticleCard,
	ArticleContent,
	ArticleCopy
} from '../components/common/Article'
import Typography from '@material-ui/core/Typography'
import RouterLink from '../components/shared/RouterLink'
import HeaderNavigation from '../components/page/HeaderNavigation'
import { RotatedGradientBg } from '../components/shared/display/RotatedGradientBg'
import { makeStyles } from '@material-ui/core/styles'
import { TimeManagement101BarsBg } from '../components/shared/display/AngledBarsBg'
import { graphql } from 'gatsby'
import Grid from '@material-ui/core/Grid'
import Spacing from '../reused-libraries/Spacing'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import RouterListItem from '../components/shared/RouterListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'

const useTimeManagement101Styles = makeStyles(theme => ({
	leadContent: {
		marginTop: "200px",
		marginLeft: theme.spacing(8),
		marginRight: theme.spacing(2),
		[theme.breakpoints.down('md')]: {
			marginLeft: theme.spacing(2),
		},
	},
	primaryHeader: {
		fontWeight: "bold",
		fontSize: "2.7rem",
		[theme.breakpoints.up('sm')]: {
			fontSize: "4rem",
		},
		[theme.breakpoints.up('md')]: {
			fontSize: "5rem",
		},
	},
	leadText: {
		maxWidth: "650px",
		[theme.breakpoints.down('md')]: {
			marginTop: "60px",
		},
	},
	grid: {
		maxWidth: "960px",
	},
	menuPaper: {
		maxWidth: "600px",
		margin: "0 auto 2rem",
	},
}), {name: "TimeManagement101"});

export default function TimeManagement101(props) {
	let {data} = props;
	const {productName} = useSiteMetadata();
	const classes = useTimeManagement101Styles();

	const title = "Time Management 101";

	let menuItems = [
		{href: "#benefits", label: "Benefits of Time Management"},
		{href: "#activities", label: "Time Management Activities"},
		{href: "#goals", label: "Time Management Goals"},
		{href: "#habits", label: "Time Management Habits"},
		{href: "#strategies", label: "Time Management Strategies"},
		{href: "#tools", label: "Time Management Tools"},
		{href: "#effects_of_poor_time_management", label: "Effects of Poor Time Management"},
	].map(item => {
		if (item.href.startsWith("#")) {
			return {...item, href: "/time-management-101/" + item.href};
		}
		return item;
	});

	return (
		<AppLayout excludeHeaderNav>
			<PageInfo
				title={"Time Management 101 - " + productName}
				description={"Explore topics on time management to improve your productivity."}
			/>

			<TimeManagement101BarsBg>
				<HeaderNavigation logoVariant="whiteout" alwaysOverlay/>

				<article>

					<RotatedGradientBg includeNavPadding>
						<ArticleContent>
							<div className={classes.leadContent}>
								<Typography variant="h1" gutterBottom className={classes.primaryHeader}>
									{title}
								</Typography>
								<div className={classes.leadText}>
									<Spacing pt={4}>
										<Typography component="h2" variant="h3" gutterBottom>
											What is time management and why is it important?
										</Typography>
										<Spacing pt={2}>
											<ArticleCopy>
												Most people have lived long enough to realize the immense value of time. For
												this reason, there has been much discussion about the topic of time management.
											</ArticleCopy>
											<ArticleCopy>
												Time management is the activity in which we attempt to plan and control the
												usage of our time in order to increase our productivity. This could be for
												several reasons such as wanting to maximize the number of tasks completed in a
												given amount of time or wanting to finish the same amount of tasks in less
												time.
											</ArticleCopy>
											<ArticleCopy>
												In this Time Management 101 article, we will introduce various topics
												related to time management to aid you in becoming a time management expert!
											</ArticleCopy>
										</Spacing>
									</Spacing>
								</div>
							</div>
						</ArticleContent>
					</RotatedGradientBg>

					<ArticleContent>
						<div className="d-flex justify-content-center">
							<Grid container spacing={2} className={classes.grid}>
								<Grid item xs={12} xl={5}>
									<Paper elevation={8} className={classes.menuPaper}>
										<Spacing p={2}><strong>Browse Topics</strong></Spacing>
										<Divider/>
										<List>
											{menuItems.map((menuItem, idx) => (
												<RouterListItem key={idx} to={menuItem.href} suppressActive>
													<ListItemText disableTypography>
														<Typography variant="body2">{menuItem.label}</Typography>
													</ListItemText>
												</RouterListItem>
											))}
										</List>
									</Paper>
								</Grid>
								<Grid item xs={12} xl={7}>

									<ArticleCard
										id="benefits"
										title="Benefits of Time Management"
										to="/benefits-of-time-management/"
										imgData={data.benefitsImg.childImageSharp.gatsbyImageData}
									>
										Do you seem to have less time nowadays? Although it's impossible in reality,
										you could be forgiven for making that claim. Modern life pulls us in many
										directions, and there is a lot of competition for our time. That's why it is
										important to be able to manage your time effectively. If you do, you may
										already know that there are some great benefits to be had. Benefits such as
										reducing stress and boosting productivity might seem obvious. But did you
										know that time management can also help strengthen your reputation and
										enable you to stand out from the crowd too? Find out more by looking at
										{' '}
										<RouterLink to="/benefits-of-time-management/">our 9 benefits of time management</RouterLink>.
									</ArticleCard>

									<ArticleCard
										id="activities"
										title="Time Management Activities"
										to="/time-management-activities/"
										imgData={data.activitiesImg.childImageSharp.gatsbyImageData}
									>
										Considering that time is a limited and valuable resource, we
										should be trying to use it wisely. Making sure your team is
										aware of this and uses time effectively is key. With that in
										mind, we present activities that can help us understand more
										about managing time. These{' '}<RouterLink to="/time-management-activities/">6 useful activities</RouterLink>
										{' '}can increase our understanding of time management. And
										that can lead to improved productivity.
									</ArticleCard>

									<ArticleCard
										id="goals"
										title="Time Management Goals"
										to="/time-management-goals/"
										imgData={data.goalsImg.childImageSharp.gatsbyImageData}
									>
										When you've got an objective in mind, it's most easily attained when you
										have a clear direction to head in. By defining short and long term goals,
										you'll have a firm grasp on your progress. And you'll be able to stay on
										task. As you consistently set goals, you'll have a better idea of what is
										realistic for you. By setting realistic goals, you're more likely to achieve
										them. Take a look at why it's important to
										{' '}
										<RouterLink to="/time-management-goals/">set goals</RouterLink>, how to effectively set
										goals, and how to achieve goals.
									</ArticleCard>

									<ArticleCard
										id="habits"
										title="Time Management Habits"
										to="/time-management-habits/"
										imgData={data.habitsImg.childImageSharp.gatsbyImageData}
									>
										Healthy habits are good to help you maintain your mind and body. Likewise,
										you can stay on track and keep your productivity up if you have good time
										management habits. For example, people who have good online discipline, keep
										goals, and have mastered the art of delegating tasks can be very effective.
										But those aren't the only time management habits that can help you make the
										most of your time. Check out our list of
										{' '}
										<RouterLink to="/time-management-habits/">10 habits to launch your productivity</RouterLink>.
									</ArticleCard>

									<ArticleCard
										id="strategies"
										title="Time Management Strategies"
										to="/time-management-strategies/"
										imgData={data.strategiesImg.childImageSharp.gatsbyImageData}
									>
										In both work and personal life, we've become so busy. Do you find you've got
										a never ending list of tasks? Although you may not get your to-do list down
										to zero, there are a number of time management strategies to help you
										improve your productivity. In an effort to assist you in finding a superior
										work/life balance, we discuss some
										{' '}
										<RouterLink to="/time-management-strategies/">common strategies to manage your time</RouterLink>.
									</ArticleCard>

									<ArticleCard
										id="tools"
										title="Time Management Tools"
										to="/time-management-tools/"
										imgData={data.toolsImg.childImageSharp.gatsbyImageData}
									>
										While you could always do your work the "old fashioned way",
										there are many online tools to help you get your job done
										more efficiently. It usually pays off to do a little
										research and work smarter, not harder. We've made a list of
										{' '}
										<RouterLink to="/time-management-tools/">a few tools</RouterLink>
										{' '}
										that you might like to add to your workflow.
									</ArticleCard>

									<ArticleCard
										id="effects_of_poor_time_management"
										title="Effects of Poor Time Management"
										to="/poor-time-management/"
										imgData={data.poorManagementImg.childImageSharp.gatsbyImageData}
									>
										It's important to recognize when you're not using time effectively. Do you
										regularly take a step back and evaluate where you're able to make
										improvements? Doing so will help you regain time, one of the most valuable
										resources you have. Are you looking to be more productive, but you're not
										sure what warning signs to look for? We've covered a few areas that can be
										an
										{' '}
										<RouterLink to="/poor-time-management/">indication of poor time management</RouterLink>.
									</ArticleCard>


									<ArticleCard
										title="Conclusion"
									>
										We hope you enjoyed looking at these time management topics and maybe even
										learned a thing or two. Whether you're a time management pro or just getting
										started, we can all make improvements. Evaluate areas in which you're not being
										most effective with your time. By setting goals, making habits, and implementing
										time management strategies, you'll be able to create a more productive and ideal
										work/life balance.
									</ArticleCard>
								</Grid>
							</Grid>
						</div>
					</ArticleContent>
				</article>
			</TimeManagement101BarsBg>

		</AppLayout>
	);
}
export const query = graphql`{
  benefitsImg: file(relativePath: {eq: "images/articles/benefits-of-time-management/cassie-boca-rz6sgZkA9Vc-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(width: 600, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  activitiesImg: file(relativePath: {eq: "images/articles/time-management-activities/gabriel-crismariu-sOK9NjLArCw-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(width: 600, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  goalsImg: file(relativePath: {eq: "images/articles/time-management-goals/isaac-smith-8XlMU62ii8I-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(width: 600, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  habitsImg: file(relativePath: {eq: "images/articles/time-management-habits/kelly-sikkema--1_RZL8BGBM-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(width: 600, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  strategiesImg: file(relativePath: {eq: "images/articles/time-management-strategies/o12-nAjil1z3eLk-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(width: 600, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  toolsImg: file(relativePath: {eq: "images/articles/time-management-tools/hunter-haley-s8OO2-t-HmQ-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(width: 600, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  poorManagementImg: file(relativePath: {eq: "images/articles/poor-time-management/jan-huber-RkPYFIny6bM-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(width: 600, placeholder: BLURRED, layout: CONSTRAINED) }
  }
}
`;
