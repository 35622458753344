import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "gatsby"
import { CookieConsention } from '../page/CookieBanner'
import ListItem from "@material-ui/core/ListItem";
import { Location } from '@reach/router'

export default class RouterListItem extends React.Component {
	constructor(props) {
		super(props);
		this.onItemClick = this.onItemClick.bind(this);
	}

	onItemClick(evt, path, onCookieConsent) {
		let {onClick} = this.props;

		onCookieConsent();

		if (onClick) {
			onClick(evt);
		}

/*
		if (!evt.defaultPrevented) {
			if (!evt.metaKey && !evt.ctrlKey) {
				// We're not opening in a new tab
				evt.preventDefault();
				history.push(path);
			}
		}
*/
	}

	render() {
		let {to, suppressActive, activeSubPath, className, children, onClick, ...otherProps} = this.props;
		let pathname = to;
		let search = "";
		if (to.pathname) {
			pathname = to.pathname;
			search = to.search || "";
		}

		return (
			<CookieConsention>
				{onCookieConsent => (
					<Location>{locationProps => {
						let {location} = locationProps;
						const active = !suppressActive && (
							location.pathname === pathname || location.pathname === pathname + '/'
							|| (activeSubPath && (location.pathname === activeSubPath || location.pathname.startsWith(activeSubPath + "/")))
						);
						return (
							<ListItem
								component={Link}
								to={pathname + search}
								button
								selected={active}
								className={className}
								onClick={evt => { this.onItemClick(evt, to, onCookieConsent); }}
								{...otherProps}
							>
								{children}
							</ListItem>
						);
					}}</Location>
				)}
			</CookieConsention>
		);
	}
}
RouterListItem.propTypes = {
	to: PropTypes.string.isRequired,
	/** true to disable the "active" class */
	suppressActive: PropTypes.bool,
	/** An optional sub-path used to consider if the button is "active" in addition to an exact math on the provided path. E.g. if this is '/abc', then both '/abc' and 'abc/def' are considered matches */
	activeSubPath: PropTypes.string,
	/** className for the ListItem */
	className: PropTypes.string,
	onClick: PropTypes.func,
};
