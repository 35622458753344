import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames'
import { darken, makeStyles } from '@material-ui/core/styles'
import { ForceHeadlineDarkTheme } from '../../../reused-libraries/materialTheme'
import { findColorByIndex } from '../../themeColors'

const useRotatedGradientBgStyles = makeStyles(theme => ({
	navPadder: {
		paddingTop: "70px",
		[theme.breakpoints.down('xs')]: {
			paddingTop: "140px",
		}
	},
	bg: {
		backgroundColor: findColorByIndex(1),  // fallback for old browsers
		background: "linear-gradient(180deg, " + findColorByIndex(1) + ", " + darken(findColorByIndex(2), 0.1) + ")", // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+
	},
}), {name: "RotatedGradientBg"});

export function RotatedGradientBg(props) {
	let {includeNavPadding, children} = props;
	const classes = useRotatedGradientBgStyles();
	return (
		<ForceHeadlineDarkTheme>
			<RotatedBgContent bgClassName={classes.bg}>
				<div className={cn({[classes.navPadder]: includeNavPadding})}>
					{children}
				</div>
			</RotatedBgContent>
		</ForceHeadlineDarkTheme>
	);
}
RotatedGradientBg.propTypes = {
	/** true to include padding for an absolutely positioned nav. The nav may be positioned absolutely to avoid including it in an e.g. <article/> */
	includeNavPadding: PropTypes.bool,
	children: PropTypes.any,
};

const useRotatedBgContentStyles = makeStyles(theme => ({
	wrapper: {
		position: "relative",
		color: theme.palette.text.primary,
		padding: "0 0 180px 0",
	},
	bgWrapper: {
		position: 'absolute',
		zIndex: -1,
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
	},
	rotatedBg: {
		width: '100%',
		height: '100%',
		transform: "skewY(3deg) translateY(-90px)",
	},
	content: {
		position: "relative",
	},
}), {name: "RotatedBgContent"});

function RotatedBgContent(props) {
	let {bgClassName, children} = props;
	const classes = useRotatedBgContentStyles();
	return (
		<div className={classes.wrapper}>
			<div className={classes.bgWrapper}>
				<div className={cn(classes.rotatedBg, bgClassName)}/>
			</div>
			<div className={classes.content}>
				{children}
			</div>
		</div>
	);
}
RotatedBgContent.propTypes = {
	bgClassName: PropTypes.string,
	children: PropTypes.any,
};
