import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { ForceHeadlineDarkTheme } from '../../../reused-libraries/materialTheme'
import { useScrollAndResizeListener } from '../event/scrollAndResizeListener'
import { findColorByIndex } from '../../themeColors'

const useTimesheetAppBarsBgStyles = makeStyles(theme => ({
	bg: {
		backgroundColor: findColorByIndex(2),  // fallback for old browsers
		background: "linear-gradient(170deg, " + findColorByIndex(1) + ", " + findColorByIndex(2) + " 70%, " + findColorByIndex(2) + ")", // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+
	},
}), {name: "TimesheetAppBarsBg"});

/**
 * Parallax angled bars in the background.
 * Components following this should use "position: relative" and set a "backgroundColor" since this
 * component doesn't handle the overflow on the y-axis due to the nature of position: sticky. We
 * could control it with Javascript, but I'd prefer not to do that because of small delays.
 */
export function TimesheetAppBarsBg(props) {
	let {children} = props;
	const classes = useTimesheetAppBarsBgStyles();
	return (
		<ForceHeadlineDarkTheme>
			<BarsBgWithTheme
				className={classes.bg}
				renderBgBars={() => (
					<React.Fragment>
						<BgBar colorIndex={2} width={260} row={-1.5} x={-850} slideDistance={500 * 1.5}/>
						<BgBar colorIndex={0} width={270} row={-0.5} x={-920} slideDistance={500 * 2.8}/>
						<BgBar colorIndex={1} width={600} row={5.2} x={-1000} slideDistance={500 * 2.1}/>
						<BgBar colorIndex={0} width={520} row={6.2} x={-800} slideDistance={500 * 1.3}/>
						<BgBar colorIndex={2} width={265} row={7.2} x={-850} slideDistance={500 * 3}/>
						<BgBar colorIndex={0} width={180} row={13} x={-950} slideDistance={500 * 2.5}/>

						<BgBar colorIndex={2} width={365} row={5.2} x={1000} alignRight slideDistance={500 * 2.2}/>
						<BgBar colorIndex={0} width={400} row={11.8} x={1000} alignRight slideDistance={500 * 3.8}/>
						<BgBar colorIndex={1} width={630} row={12.8} x={750} alignRight slideDistance={500 * 1.2}/>
						<BgBar colorIndex={1} width={460} row={19} x={1000} alignRight slideDistance={500 * 2.7}/>
					</React.Fragment>
				)}
				children={children}
			/>
		</ForceHeadlineDarkTheme>
	);
}
TimesheetAppBarsBg.propTypes = {
	children: PropTypes.any,
};

/**
 * Parallax angled bars in the background.
 * Components following this should use "position: relative" and set a "backgroundColor" since this
 * component doesn't handle the overflow on the y-axis due to the nature of position: sticky. We
 * could control it with Javascript, but I'd prefer not to do that because of small delays.
 */
export function FreelanceBarsBg(props) {
	let {children} = props;
	const classes = useTimesheetAppBarsBgStyles();
	return (
		<ForceHeadlineDarkTheme>
			<BarsBgWithTheme
				className={classes.bg}
				renderBgBars={() => (
					<React.Fragment>
						<BgBar colorIndex={0} width={270} row={0} x={-960} slideDistance={500 * 1.8}/>
						<BgBar colorIndex={2} width={260} row={1} x={-850} slideDistance={500 * 3.5}/>
						<BgBar colorIndex={1} width={580} row={7} x={-950} slideDistance={500 * 3.5}/>
						<BgBar colorIndex={2} width={500} row={8} x={-1100} slideDistance={500 * 2}/>
						<BgBar colorIndex={1} width={220} row={11} x={-1050} slideDistance={500 * 2.5}/>

						<BgBar colorIndex={2} width={325} row={7} x={1000} alignRight slideDistance={500 * 3.2}/>
						<BgBar colorIndex={2} width={270} row={12} x={1000} alignRight slideDistance={500 * 3}/>
						<BgBar colorIndex={0} width={400} row={13} x={1000} alignRight slideDistance={500 * 5}/>
						<BgBar colorIndex={1} width={550} row={14} x={880} alignRight slideDistance={500 * 2}/>
						<BgBar colorIndex={0} width={300} row={22} x={850} alignRight slideDistance={500 * 2.8}/>
					</React.Fragment>
				)}
				children={children}
			/>
		</ForceHeadlineDarkTheme>
	);
}
FreelanceBarsBg.propTypes = {
	children: PropTypes.any,
};

export function TimeManagement101BarsBg(props) {
	let {children} = props;
	return (
		<BarsBgWithTheme
			parallax={false}
			renderBgBars={() => (
				<React.Fragment>
					<BgBar colorIndex={0} width={210} row={1} x={-780} slideDistance={500 * 2.2}/>
					<BgBar colorIndex={2} width={220} row={8} x={-860} slideDistance={500 * 3.1}/>
					<BgBar colorIndex={0} width={180} row={9} x={-740} slideDistance={500 * 2.1}/>
					<BgBar colorIndex={2} width={240} row={20} x={-700} slideDistance={500 * 1.3}/>
					<BgBar colorIndex={0} width={200} row={21} x={-600} slideDistance={500 * 1.3}/>
					<BgBar colorIndex={0} width={200} row={32} x={-600} slideDistance={500 * 2.2}/>
					<BgBar colorIndex={2} width={300} row={33} x={-500} slideDistance={500 * 3.1}/>
					<BgBar colorIndex={1} width={220} row={43} x={-550} slideDistance={500 * 2.4}/>
					<BgBar colorIndex={2} width={100} row={44} x={-590} slideDistance={500 * 1.4}/>
					<BgBar colorIndex={0} width={300} row={50} x={-800} slideDistance={500 * 2.7}/>
					<BgBar colorIndex={1} width={250} row={58} x={-600} slideDistance={500 * 2.3}/>
					<BgBar colorIndex={2} width={150} row={59} x={-670} slideDistance={500 * 1.2}/>
					<BgBar colorIndex={0} width={150} row={68} x={-670} slideDistance={500 * 2.2}/>
					<BgBar colorIndex={2} width={230} row={69} x={-620} slideDistance={500 * 2.2}/>

					<BgBar colorIndex={2} width={280} row={8} x={700} alignRight slideDistance={500 * 2.3}/>
					<BgBar colorIndex={1} width={240} row={9} x={520} alignRight slideDistance={500 * 2.9}/>
					<BgBar colorIndex={1} width={270} row={21} x={550} alignRight slideDistance={500 * 2.9}/>
					<BgBar colorIndex={2} width={250} row={22} x={750} alignRight slideDistance={500 * 1.5}/>
					<BgBar colorIndex={0} width={310} row={30} x={700} alignRight slideDistance={500 * 2.1}/>
					<BgBar colorIndex={2} width={250} row={41} x={600} alignRight slideDistance={500 * 1.6}/>
					<BgBar colorIndex={1} width={200} row={53} x={800} alignRight slideDistance={500 * 2.7}/>
					<BgBar colorIndex={2} width={250} row={61} x={900} alignRight slideDistance={500 * 1.6}/>
					<BgBar colorIndex={0} width={400} row={62} x={810} alignRight slideDistance={500 * 2.2}/>
					<BgBar colorIndex={2} width={350} row={70} x={980} alignRight slideDistance={500 * 1.5}/>
					<BgBar colorIndex={1} width={220} row={77} x={780} alignRight slideDistance={500 * 2.7}/>
					<BgBar colorIndex={2} width={140} row={78} x={820} alignRight slideDistance={500 * 2.1}/>
				</React.Fragment>
			)}
			children={children}
		/>
	);
}
TimeManagement101BarsBg.propTypes = {
	children: PropTypes.any,
};

const useBarsBgWithThemeStyles = makeStyles(theme => ({
	wrapper: {
		position: "relative",
		color: theme.palette.text.primary,
	},
	bgContainer: {
		fallbacks: [{position: "-webkit-sticky"}],
		position: "sticky",
		top: 0,
		left: 0,
	},
	barsContainer: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		overflow: "hidden",
	},
	barsAnchor: {
		position: "relative",
		top: 0,
		left: "50%",
		width: 0,
	},
	mainContent: {
		position: "relative",
	},
}), {name: "BarsBgWithTheme"});

function BarsBgWithTheme(props) {
	let {className, parallax = true, renderBgBars, children} = props;
	const classes = useBarsBgWithThemeStyles();

	const containerRef = React.useRef();
	const barsContainerRef = React.useRef();
	const mainContentRef = React.useRef();

	function adjustContainerHeight() {
		// We have to set the height since "position: sticky" doesn't allow us to set "height: 100%"
		// according to the parent element. Additionally, we want to use "overflow: hidden", but we
		// can't put that on the parent element or the parent element will become the scrolling
		// ancestor of our "position: sticky", and it would move incorrectly.
		let height = mainContentRef.current ? mainContentRef.current.getBoundingClientRect().height : 0;
		if (barsContainerRef.current) {
			barsContainerRef.current.style = "height:" + height + "px";
		}
	}

	React.useEffect(adjustContainerHeight, []);

	useScrollAndResizeListener(scrollY => {
		// Parallax scrolling
		if (containerRef.current) {
			let translateY = !parallax ? 0 : Math.max(0, scrollY) * -0.7;
			let transform = "translate(0," + translateY + "px)";
			containerRef.current.style = "transform:" + transform;
		}
		adjustContainerHeight();
	}, [containerRef.current, parallax]);

	return (
		<div className={cn(className, classes.wrapper)}>
			<div ref={containerRef} className={parallax ? classes.bgContainer : undefined}>
				<div ref={barsContainerRef} className={classes.barsContainer}>
					<div className={classes.barsAnchor}>
						{renderBgBars()}
					</div>
				</div>
			</div>
			<div ref={mainContentRef} className={classes.mainContent}>
				{children}
			</div>
		</div>
	);
}
BarsBgWithTheme.propTypes = {
	className: PropTypes.string,
	/** Enable parallax scroll, defaults to true */
	parallax: PropTypes.bool,
	/** Called with no params to render the bg bars */
	renderBgBars: PropTypes.func.isRequired,
	children: PropTypes.any,
};

const barHeight = 62;
const skewAngle = 22; // Do not put 90 or similar degrees. It'll cause a divide by zero error
const skewedBarHeight = barHeight / Math.sin((90 - skewAngle) / 180 * Math.PI);

const useBgBarStyles = props => {
	let {fromStyle} = props;
	// Using callback instead of a function in keyframes: https://github.com/cssinjs/jss/issues/1216
	const deps = [JSON.stringify(fromStyle)];
	return React.useCallback(makeStyles(theme => ({
		'@keyframes bgBarAnimation': {
			from: {
				...fromStyle,
			},
		},
		imgHidden: {
			display: "none",
		},
		animatedBar: {
			position: "absolute",
			transformOrigin: "left center",
			animationName: '$bgBarAnimation',
			animationDuration: "0.8s",
			animationTimingFunction: "ease-in-out",
		},
		mainBar: {
			height: skewedBarHeight,
		},
		shadowBar: {
			zIndex: -1,
			height: skewedBarHeight,
			boxShadow: theme.shadows[5],
		},
	}), {name: "BgBar"}), deps)(props);
};

function BgBar(props) {
	let {colorIndex, width, row, x, alignRight, slideDistance = 500} = props;

	let color = findColorByIndex(colorIndex);

	let skew = "skew(0deg," + -skewAngle + "deg)";
	let translateX = x;
	let translateY = row * skewedBarHeight;
	let fromStyle = {transform: skew + " translate(" + (translateX + (alignRight ? -width + slideDistance : -slideDistance)) + "px," + translateY + "px)"};
	let toStyle = {transform: skew + " translate(" + (translateX + (alignRight ? -width : 0)) + "px," + translateY + "px)"};
	const classes = useBgBarStyles({fromStyle: fromStyle});
	return (
		<React.Fragment>
			<div
				className={cn(classes.animatedBar, classes.mainBar)}
				style={{
					backgroundColor: color,
					width: width + "px",
					...toStyle,
				}}
			/>
			<div
				className={cn(classes.animatedBar, classes.shadowBar)}
				style={{
					width: width + "px",
					...toStyle,
				}}
			/>
		</React.Fragment>
	);
}
BgBar.propTypes = {
	/** The zero-based color index */
	colorIndex: PropTypes.number.isRequired,
	/** The amount of horizontal space to take up, such as "300" */
	width: PropTypes.number.isRequired,
	/** The vertical row number to translate by, such as 200 */
	row: PropTypes.number.isRequired,
	/** The horizontal translation value, such as 100 */
	x: PropTypes.number.isRequired,
	/** true to align the right side of the bar as the the starting position */
	alignRight: PropTypes.bool,
	/** How far to animate */
	slideDistance: PropTypes.number,
};
